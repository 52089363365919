import React from "react";
import { Link } from "gatsby";
import contact from "../../assets/images/zest/contact/contact.png";
import shape1 from "../../assets/images/shape/shape1.svg";
import shape2 from "../../assets/images/shape/shape2.png";
import shape3 from "../../assets/images/shape/shape3.png";

const PageBanner = ({
  pageTitle,
  homePageUrl,
  homePageText,
  activePageText,
}) => {
  return (
    <div className="page-title-area mb-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="page-title-content">
              <h2 className="mb-5">{pageTitle}</h2>
              <h2 className="text-blue mb-3">Let's Talk About Goals</h2>
              <h3>How Can We Help You?</h3>
              <p>
                Our team of experts are recognized for creating value and
                producing excellence. We all operate in a competitive market so,
                shouldn’t you work with a team that gives your business a
                competitive advantage?
              </p>
            </div>
          </div>

          <div className="col-lg-6 contact-image">
            <img src={contact} alt="contact" />
          </div>
        </div>
      </div>

      <div className="shape-img1">
        <img src={shape1} alt="banner" />
      </div>
      <div className="shape-img2">
        <img src={shape2} alt="banner" />
      </div>
      <div className="shape-img3">
        <img src={shape3} alt="banner" />
      </div>
    </div>
  );
};

export default PageBanner;
